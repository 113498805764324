import cookie from 'react-cookies';
const GetInscricaoImpressao = (idInscricao,onSuccess,onFailure) => {
    const token = cookie.load('access_token');

    const requestOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }

    };

    return fetch('api/evento/inscricao/' + idInscricao, requestOptions).then(r => {
        if (r.ok) {       
            r.json().then(d => onSuccess(d))
        } else {
            r.text().then(t => onFailure(t));
        }
    })


};

export default GetInscricaoImpressao;