import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';

export default class CardEvento extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usuario: cookie.load('usuario'),
        }
    }

    render() {
        const evento = this.props.evento;
        return (
            <div class="row mt-3">
                <div class="card">
                    <div class="card-wrapper">
                        <div class="row align-items-center">
                            <div class="col-12 col-md">
                                <div class="image-wrapper">
                                    <img src={evento.imagem} alt="Mobirise Website Builder" style={{ opacity: evento.encerrado ? 0.5 : 1 }} />
                                </div>
                            </div>
                            <div class="col-12 col-md">
                                <div class="card-box">
                                    <h5 class="card-title mbr-fonts-style m-0 mb-3 display-5">
                                        <strong>{evento.nome}{evento.encerrado ? " [Encerrado]" : null}</strong>
                                    </h5>
                                    <h6 class="card-subtitle mbr-fonts-style mb-3 display-4">
                                        <strong> Início:</strong>  {moment(evento.dataInicio).format('lll')} <strong>- Fim:</strong> {moment(evento.dataFim).format('lll')}
                                    </h6>

                                    {
                                        this.state.usuario && evento.estado ?
                                            <>
                                                <h6 class="card-subtitle mbr-fonts-style mb-3 display-4">
                                                    <strong> Situação:</strong> {evento.estado}
                                                </h6>
                                            </> :
                                            null
                                    }

                                    <h6 class="card-subtitle mbr-fonts-style mb-3 display-4">
                                        <strong>Local: </strong>{evento.local}
                                    </h6>
                                    <h6 class="card-subtitle mbr-fonts-style mb-3 display-4">
                                        <strong>Responsável: </strong>{evento.responsavel}</h6>
                                    <p class="mbr-text mbr-fonts-style display-7">
                                        {evento.apresentacao}
                                    </p>
                                    <div class="social-row display-7">
                                        <Link to={"/eventos/" + evento.id} className="btn btn-primary" >Ver detalhes</Link>
                                        {
                                            this.state.usuario && this.state.usuario.admin ?
                                                <>
                                                    <input type="button" className="btn btn-danger" value="Editar" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e) => this.props.editarEvento(evento.id)} />


                                                </>
                                                : null
                                        }
                                        {
                                            this.state.usuario ?
                                                <>
                                                    <a className="btn btn-success" href={"http://www.megagold.com.br/static/media/certificados/" + evento.id + "/" + this.state.usuario.id + ".pdf"} target="_blank">Emitir Certificado</a>
                                                </>
                                                : null
                                        }
                                        {
                                            this.props.meusCursos ?
                                                <Link to={"/inscricao/" + evento.id} className="btn btn-secondary" >Ver inscrição</Link>
                                                : ""

                                        }


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
