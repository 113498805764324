import cookie from 'react-cookies';
const ResetUsuario = (idUsuario) => {
    const token = cookie.load('access_token');

    const requestOptions = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
      
    };
    return fetch('api/usuario/reset/'+idUsuario, requestOptions)

};

export default ResetUsuario;