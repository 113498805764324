
import { Button } from 'bootstrap';
import InputMask from "react-input-mask";
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PostUsuario from '../Api/PostUsuario';
import moment from 'moment'
import { Redirect } from 'react-router';
import cookie from 'react-cookies';
import GetEvento from '../Api/GetEvento';
import CardEvento from '../Tools/CardEvento';
import GetInscricao from '../Api/GetInscricao';
import DeleteInscricao from '../Api/DeleteInscricao';
import { Link } from 'react-router-dom';

const initialState = {
    nome: "",
    tipoPessoa: "F",
    cpf: "",
    email: "",
    cargo: "",
    telefone: "",
    sexo: "",
    nascimento: "",
    estado: "RO",
    cidade: "",
    endereco: "",
}

export class CadastrarParticipante extends Component {
    static displayName = CadastrarParticipante.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            idEvento: this.props.match.params.idEvento,
            estado: "RO",
            cidade: "",
            tipoPessoa: "F",
            usuario: cookie.load('usuario'),

        }
    }


    componentDidMount() {
        console.log(this.state.usuario);

        window.scrollTo(0, 0);
        if (this.state.usuario.tipoPessoa === "J")
            this.setState({ instituicao: this.state.usuario.nome })
        this.carregarEvento();


    }

    getSituacao = (idSituacao) => {
        switch (idSituacao) {
            case 0:
                return 'Aguardando aprovação'; break;
            case 1:
                return 'Aprovado'; break;
            case 2:
                return 'Cancelado'; break;
            default:
                return 'Não identificado';
        }
    }

    carregarEvento() {
        GetEvento(this.state.idEvento, (e) => this.setState({ evento: e }, this.carregarInscricao))
    }
    carregarInscricao() {
        GetInscricao(this.state.idEvento, (i) => this.setState({ inscricao: i, loading: false }))
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    isValid() {
        if (this.state.nome && this.state.email && this.state.nome && (this.state.cpf || this.state.cnpj) && this.state.telefone && this.state.estado && this.state.cidade && this.state.endereco && ((this.state.sexo && this.state.nascimento) || this.state.tipoPessoa === "J")) {
            if (this.state.tipoPessoa === "F" && this.state.cpf.length < 14) {
                toast.error("CPF inválido");
                return false;
            }
            if (this.state.tipoPessoa === "J" && this.state.cnpj.length < 14) {
                toast.error("CNPJ inválido");
                return false;
            }


            return true;


        } else {
            toast.error("Todos os campos são obrigatórios");
            return false;
        }

    }

    concluir() {
        if (this.isValid()) {
            PostUsuario({
                nome: this.state.nome,
                tipoPessoa: this.state.tipoPessoa,
                cpf: this.state.cpf ? this.state.cpf.replace(/[^\d]/g, '') : null,
                cnpj: this.state.cnpj ? this.state.cnpj.replace(/[^\d]/g, '') : null,
                email: this.state.email,
                cargo: this.state.cargo,
                instituicao: this.state.instituicao,
                telefone: this.state.telefone,
                senha: this.state.senha,
                sexo: this.state.sexo,
                nascimento: this.state.nascimento,
                estado: this.state.estado,
                cidade: this.state.cidade,
                endereco: this.state.endereco,
                idInscricao: this.state.inscricao.id,
                idPrecoEvento: this.state.inscricao.idPrecoEvento,
            }).then(r => {
                if (r.ok) {
                    r.json().then(data => {
                        toast.success("Novo participante cadastrado com sucesso");
                        this.setState(initialState);
                        this.carregarInscricao();
                    })

                } else {
                    r.text().then(t => toast.error(t))
                }
            })
        }
    }

    excluir(idUsuario) {
        if (window.confirm("Tem certeza que deseja remover o participante?")) {
            DeleteInscricao(this.state.idEvento,idUsuario, (t) => {
                toast.success(t);
                this.carregarInscricao();
            }, (e) => toast.error(e));
        }
    }

    render() {
        if (this.state.loading) {
            return "Carregando";
        }


        if (this.state.redirect) {
            if (!this.state.idEvento)
                return <Redirect to={"/painel"} />
            else
                return <Redirect to={"/eventos/" + this.state.idEvento + "/true"} />
        }
        return (

            <div>
                <section data-bs-version="5.1" class="header19 cid-t0bvGWXrL2" style={{ paddingTop: 1 + "rem" }} id="header19-0">
                    <div class="container">
                        <div class="row justify-content-center mt-4">
                            <div class="offset-lg-3 mbr-form" data-form-type="formoid">


                                <CardEvento evento={this.state.evento} />


                                <div class="row mt-2">
                                    <div class="col-12 form-group" data-for="name">
                                        <h6 className="display-6">Inscrição</h6>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Data de inscrição</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">

                                        <input class="form-control" type="text" value={moment(this.state.inscricao.dataInscricao).format("DD/MM/YYYY")} disabled={true} />

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Tipo</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">

                                        <input class="form-control" type="text" value={this.state.inscricao.preco.descricao} disabled={true} />

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Situação</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">

                                        <input class="form-control" type="text" value={this.getSituacao(this.state.inscricao.situacao)} disabled={true} />

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Participantes</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">

                                        <input class="form-control" type="text" value={this.state.inscricao.participantes.length + "/" + this.state.inscricao.preco.pessoas + "+"} disabled={true} />

                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-lg-8 form-group mb-3" data-for="name">
                                        <div class="table-responsive">
                                            <table class="table">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Nome</th>
                                                        <th scope="col">CPF</th>
                                                        <th scope="col">Ação</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.inscricao.participantes.map((p, i) =>
                                                            <tr>
                                                                <th scope="row">{i + 1}</th>
                                                                <td>{p.nome}</td>
                                                                <td>
                                                                    {p.cpf}</td>
                                                                <td>
                                                                    <input type="button" className="btn btn-sm btn-danger" value="Excluir" onClick={(e) => this.excluir(p.id)} />
                                                                    <Link to={ "/inscricao/imprimir/"+p.idInscricao}>Imprimir</Link>
                                                                </td>
                                                            </tr>)

                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>





                                <div class="row">
                                    <div class="col-12 form-group" data-for="name">
                                        <h6 className="display-6">Adicionar participante</h6>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                        <h6 className="display-7">Nome completo</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input type="text" name="nome" placeholder="Informe o seu nome completo" class="form-control" value={this.state.nome} onChange={this.onChange} />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                        <h6 className="display-7">CPF</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <InputMask name="cpf" mask="999.999.999-99" className="form-control" maskChar={null} value={this.state.cpf} onChange={this.onChange} placeholder="Informe o seu número de CPF" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                        <h6 className="display-7">Cargo/Função</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input name="cargo" type="text" className="form-control" value={this.state.cargo} onChange={this.onChange} placeholder="Informe o seu cargo ou função" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                        <h6 className="display-7">Orgão</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input name="instituicao" type="text" className="form-control" value={this.state.instituicao} onChange={this.onChange} placeholder="Informe o nome da sua instituição" />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                        <h6 className="display-7">E-mail</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input type="email" name="email" placeholder="exemplo@email.com" class="form-control" value={this.state.email} onChange={this.onChange} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                        <h6 className="display-7">Telefone</h6>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input type="number" name="telefone" placeholder="DD + Telefone" class="form-control" value={this.state.telefone} onChange={this.onChange} />
                                    </div>
                                </div>


                                <div class="row">
                                    <div class="col-lg-8 col-12 " data-for="name">
                                        <hr />
                                    </div>
                                </div>
                                {
                                    this.state.tipoPessoa === "F" ?
                                        <>
                                            <div class="row">
                                                <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <h6 className="display-7">Sexo</h6>
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="_lblSexoM" value="M" checked={this.state.sexo == "M"} onChange={() => this.setState({ sexo: "M" })} />
                                                        <label class="form-check-label" for="_lblSexoM">Masculino</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="_lblSexoF" value="F" checked={this.state.sexo == "F"} onChange={() => this.setState({ sexo: "F" })} />
                                                        <label class="form-check-label" for="_lblSexoF">Feminino</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <h6 className="display-7">Data de nascimento</h6>
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <input type="date" name="name" placeholder="Data e hora do início do evento" class="form-control" value={this.state.nascimento ? this.state.nascimento : ""} onChange={(e) => this.setState({ nascimento: moment(e.target.value).format("YYYY-MM-DD") })} />
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }


                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Estado</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <select className="form-control" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado}>
                                            <option>AC</option>
                                            <option>AL</option>
                                            <option>AP</option>
                                            <option>AM</option>
                                            <option>BA</option>
                                            <option>CE</option>
                                            <option>DF</option>
                                            <option>ES</option>
                                            <option>GO</option>
                                            <option>MA</option>
                                            <option>MT</option>
                                            <option>MS</option>
                                            <option>MG</option>
                                            <option>PA</option>
                                            <option>PB</option>
                                            <option>PR</option>
                                            <option>PE</option>
                                            <option>PI</option>
                                            <option>RJ</option>
                                            <option>RN</option>
                                            <option>RS</option>
                                            <option>RO</option>
                                            <option>RR</option>
                                            <option>SC</option>
                                            <option>SP</option>
                                            <option>SE</option>
                                            <option>TO</option>

                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Cidade</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input type="text" name="cidade" placeholder="Informe a cidade do participante" class="form-control" value={this.state.cidade} onChange={this.onChange} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Endereço</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input type="text" name="endereco" placeholder="Informe o bairro do participante, logradouro e número" class="form-control" value={this.state.endereco} onChange={this.onChange} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-lg-8 text-end" data-for="name">
                                        <input type="button" className="btn btn-primary" value="Concluir" onClick={(e) => this.concluir()} />
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}
