import cookie from 'react-cookies';
const GetMembros = () => {
    const token = cookie.load('access_token');
       
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }

        };

        return fetch('api/membro/',requestOptions)

    
};

export default GetMembros;