
import { Button } from 'bootstrap';
import EmblaCarousel from 'embla-carousel'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Eventos } from '../Usuario/Eventos';
import { SejaMembro } from './SejaMembro';


export class Home extends Component {
    static displayName = Home.name;
    componentDidMount() {
        //this.embla = useEmblaCarousel();
        const options = { loop: false }
        EmblaCarousel(this.embla, options);
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <section data-bs-version="5.1" className="header1 cid-s48MCQYojq mbr-fullscreen mbr-parallax-background" id="header1-f">



                    <div className="mbr-overlay"></div>

                    <div className="align-center container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-8">
                                <h1 className="mbr-section-title mbr-fonts-style mb-3 display-1"><strong>MEGA GOLD</strong></h1>

                                <p className="mbr-text mbr-fonts-style display-7">Conheça nossos cursos e treinamentos para capacitação profissional</p>
                                <div className="mbr-section-btn mt-3">
                                    <a className="btn btn-success display-4" href="#sobre-nos">Saber Mais</a>
                                    <Link className="btn btn-success-outline display-4" to={ "/eventos"}>Cursos &amp; treinamentos &gt;</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Eventos />
                <section data-bs-version="5.1" className="content1 cid-s48udlf8KU" id="sobre-nos">

                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="title col-12 col-md-9">
                                <h3 className="mbr-section-title mbr-fonts-style align-center mb-4 display-2"><strong>Sobre nós</strong></h3>
                                <h4 className="mbr-section-subtitle align-center mbr-fonts-style mb-4 display-7">A MegaGold em parceria com diversos setores de capacitação tem o prazer de oferecer o aperfeiçoamento através da educação, com qualidade seriedade, responsabilidade e ética embasadas em modernas tecnologias de gestão.  Assim, estamos preparados para oferecer condições de desenvolvimento pessoal e profissional, as pessoas que queiram aprimorar-se para enfrentar o futuro, melhor qualificadas.</h4>

                            </div>
                        </div>
                    </div>
                </section>

                <section data-bs-version="5.1" className="content16 cid-sZYOL9hkRX" id="content16-n">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10">
                                <div className="mbr-section-head align-center mb-4">
                                    <h3 className="mbr-section-title mb-0 mbr-fonts-style display-2">
                                        <strong>Certidões</strong>
                                    </h3>

                                </div>
                                <div id="bootstrap-accordion_11" className="panel-group accordionStyles accordion" role="tablist" aria-multiselectable="true">
                                    <div className="card mb-3">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse1_11" aria-expanded="false" aria-controls="collapse1">
                                                <h6 className="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>CND ESTADUAL - VALIDADE: 02/08/2022</strong></h6>
                                                <span className="sign mbr-iconfont mbri-arrow-down"></span>
                                            </a>
                                        </div>
                                        <div id="collapse1_11" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_11">
                                            <div className="panel-body">
                                                <a href="http://www.megagold.com.br/static/media/documentos/CND-ESTADUAL-02.08.2022.pdf" target="_blank" className="mbr-fonts-style panel-text display-4">Acessar documento</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse2_11" aria-expanded="false" aria-controls="collapse2">
                                                <h6 className="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>CND FEDERAL - VALIDADE: 29/10/2022</strong></h6>
                                                <span className="sign mbr-iconfont mbri-arrow-down"></span>
                                            </a>
                                        </div>
                                        <div id="collapse2_11" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_11">
                                            <div className="panel-body">
                                                <a href="http://www.megagold.com.br/static/media/documentos/CND-FEDERAL-29.10.2022.pdf" target="_blank" className="mbr-fonts-style panel-text display-4">Acessar documento</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse3_11" aria-expanded="false" aria-controls="collapse3">
                                                <h6 className="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>CND MUNICIPAL - VALIDADE 08/06/2022</strong></h6>
                                                <span className="sign mbr-iconfont mbri-arrow-down"></span>
                                            </a>
                                        </div>
                                        <div id="collapse3_11" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_11">
                                            <div className="panel-body">
                                                <a href="http://www.megagold.com.br/static/media/documentos/MUNICIPAL-08.06.2022.pdf" target="_blank" className="mbr-fonts-style panel-text display-4">Acessar documento</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse4_11" aria-expanded="false" aria-controls="collapse4">
                                                <h6 className="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>CNDT - VALIDADE: 10/06/2022</strong></h6>
                                                <span className="sign mbr-iconfont mbri-arrow-down"></span>
                                            </a>
                                        </div>
                                        <div id="collapse4_11" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_11">
                                            <div className="panel-body">
                                                <a href="http://www.megagold.com.br/static/media/documentos/CNDT-10.06.22.pdf" target="_blank" className="mbr-fonts-style panel-text display-4">Acessar documento</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse5_11" aria-expanded="false" aria-controls="collapse5">
                                                <h6 className="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>CND CONCORDATA E FALÊNCIA - VALIDADE: 09/06/2022</strong></h6>
                                                <span className="sign mbr-iconfont mbri-arrow-down"></span>
                                            </a>
                                        </div>
                                        <div id="collapse5_11" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_11">
                                            <div className="panel-body">
                                                <a href="http://www.megagold.com.br/static/media/documentos/FALENCIA-09.06.2022.pdf" target="_blank" className="mbr-fonts-style panel-text display-4">Acessar documento</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-header" role="tab" id="headingOne">
                                            <a role="button" className="panel-title collapsed" data-toggle="collapse" data-bs-toggle="collapse" data-core="" href="#collapse6_11" aria-expanded="false" aria-controls="collapse6">
                                                <h6 className="panel-title-edit mbr-fonts-style mb-0 display-7"><strong>CND FGTS - VALIDADE: 11/06/2022</strong></h6>
                                                <span className="sign mbr-iconfont mbri-arrow-down"></span>
                                            </a>
                                        </div>
                                        <div id="collapse6_11" className="panel-collapse noScroll collapse" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion" data-bs-parent="#bootstrap-accordion_11">
                                            <div className="panel-body">
                                                <a href="http://www.megagold.com.br/static/media/documentos/FGTS-11.06.2022.pdf" target="_blank" className="mbr-fonts-style panel-text display-4">Acessar documento</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section data-bs-version="5.1" className="content16 cid-sZYOL9hkRX" >
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-10">
                                <div className="mbr-section-head align-center mb-4">
                                    <h3 className="mbr-section-title mb-0 mbr-fonts-style display-2">
                                        <strong>Redes Sociais</strong>
                                    </h3>

                                </div>
                                <div id="curator-feed-default-feed-layout"><a href="https://curator.io" target="_blank" class="crt-logo crt-tag">Powered by Curator.io</a></div>
                            </div>
                        </div>
                    </div>
                </section>

               <SejaMembro/>
               

            </div >
        );
    }
}
