
import { Button } from 'bootstrap';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify'
import DeleteEvento from '../Api/DeleteEvento';
import GetEvento from '../Api/GetEvento';
import PostEvento from '../Api/PostEvento';
import { BuscarMembro } from '../Tools/BuscarMembro';
import { Obrigatorio } from '../Tools/Obrigatorio';

const initialState = {
    loading: false,
    membros: [],
    id: 0,
    nome: "",
    apresentacao: "",
    imagem: "",
    descricao: "",
    dataInicio: "",
    dataFim: "",
    local: "",
    responsavel: "",
    valor: "",
}

export class CadastrarEvento extends Component {
    static displayName = CadastrarEvento.name;
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    componentDidMount() {
        window.scrollTo(0, 0);

    }
    carregarEvento(idEvento) {
        if (idEvento) {
            GetEvento(idEvento, (evento) => {
                this.setState(evento);
            })
        } else {
            this.setState(initialState);
        }
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    salvarEvento() {
        if (this.validarCadastro()) {
            PostEvento(this.state, (t) => {
                toast.success(t);
                this.carregarEvento(null);
                this.props.carregarEventos();
            }, (t) => toast.error(t));
        }
    }

    excluirEvento() {
        if (window.confirm("Tem certeza que deseja excluir o evento?")) {
            DeleteEvento(this.state.id, (t) => {
                toast.success(t);
                this.carregarEvento(null);
                this.props.carregarEventos();
            }, (t) => toast.error(t))
        }
    }
    validarCadastro() {
        if (!this.state.nome) {
            toast.error("Informe o nome do evento");
            return false;
        }
        if (!this.state.apresentacao) {
            toast.error("Informe o texto de apresentacao");
            return false;
        }
        if (!this.state.imagem) {
            toast.error("Informe o endereço da imagem");
            return false;
        }
        if (!this.state.descricao) {
            toast.error("Informe o texto de descrição");
            return false;
        }
        if (!this.state.dataInicio) {
            toast.error("Informe a data de início do evento");
            return false;
        }
        if (!this.state.dataFim) {
            toast.error("Informe a data de fim do evento");
            return false;
        }
        if (!this.state.local) {
            toast.error("Informe o local de realização do evento");
            return false;
        }
        if (!this.state.responsavel) {
            toast.error("Informe o responsável pela realização do evento");
            return false;
        }
        if (!this.state.valor) {
            toast.error("Informe o valor do evento");
            return false;
        }
        return true;
    }

    render() {
        if (this.state.loading)
            return "Carregando..."
        return (
            <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl h-auto">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Evento</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">



                            <div class="row justify-content-center mt-4">
                                <div class="offset-lg-3 mbr-form" data-form-type="formoid">
                                    <div class="row">
                                        <div class="col-12 form-group" data-for="name">
                                            <h6 className="display-6">Nome do evento<Obrigatorio /></h6>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <input type="text" name="nome" placeholder="Nome de identificação do evento" class="form-control" value={this.state.nome} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg col-md-12 col-sm-12 form-group" data-for="name">
                                            <h7 className="display-7">Apresentação do evento<Obrigatorio /></h7>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <textarea className="form-control" placeholder="Texto de apresentação que será exibido no cadastro do evento" name="apresentacao" value={this.state.apresentacao} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <h6 className="display-7">Imagem<Obrigatorio /></h6>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <input type="text" placeholder="informe o endereço da imagem .png ou .jpg" class="form-control" name="imagem" value={this.state.imagem} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg col-md-12 col-sm-12 form-group" data-for="name">
                                            <h7 className="display-7">Descrição do evento<Obrigatorio /></h7>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <textarea className="form-control" placeholder="Dê mais informações sobre o evento" name="descricao" value={this.state.descricao} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <h6 className="display-7">Data do início<Obrigatorio /></h6>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <input type="datetime-local" placeholder="Data e hora do início do evento" class="form-control" id="name-form8-2" name="dataInicio" value={this.state.dataInicio} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <h6 className="display-7">Data do fim<Obrigatorio /></h6>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <input type="datetime-local" placeholder="Data e hora do fim do evento" class="form-control" id="name-form8-2" name="dataFim" value={this.state.dataFim} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <h6 className="display-7">Local<Obrigatorio /></h6>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <input type="text" placeholder="Endereço ou localização de onde ocorrerá o evento" class="form-control" name="local" value={this.state.local} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <h6 className="display-7">Responsável<Obrigatorio /></h6>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <input type="text" placeholder="Entidade responsável pela realização do evento" class="form-control" id="name-form8-2" name="responsavel" value={this.state.responsavel} onChange={this.onChange} />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <h6 className="display-7">Valor<Obrigatorio /></h6>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <input type="text" placeholder="Valor a pagar para a realização do evento" class="form-control" id="name-form8-2" name="valor" value={this.state.valor} onChange={(e) => e.target.value != "" ? this.setState({ valor: parseFloat(e.target.value) }) : this.setState({ valor: 0 })} />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <Link to="/cadastrarmembro" className="btn btn-primary" target="_blank" >Cadastrar Membro</Link>
                                        </div>

                                    </div>
                                    <div class="row">
                                        <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <h6 className="display-7">Equipe</h6>
                                        </div>
                                        <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                            <BuscarMembro recebeItem={(i) => i && !this.state.membros.includes(i) ? this.setState({ membros: [...this.state.membros, i] }) : null} />
                                        </div>
                                    </div>
                                    <div class="row">
                                        {
                                            this.state.membros ?
                                                this.state.membros.length <= 0 ?
                                                    <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                        Nenhum item disponível
                                            </div>
                                                    :
                                                    this.state.membros.map((m) =>
                                                        <div className="row">
                                                            <div className="col">
                                                                {m.nome}
                                                            </div>
                                                            <div className="col">
                                                                {m.cargo}
                                                            </div>
                                                            <div className="col">
                                                                <input type="button" className="btn btn-danger" value="Remover" onClick={(e) => this.setState({
                                                                    membros: this.state.membros.filter((_m) => _m != m)
                                                                })} />
                                                            </div>
                                                        </div>
                                                    )
                                                : null
                                        }

                                    </div>

                                </div>
                            </div>

                        </div>
                        <div class="modal-footer">
                            {
                                this.state.id != 0 ?
                                    <button type="button" class="btn btn-outline-secondary" onClick={(e)=> this.excluirEvento()}>Excluir</button>
                                    : null
                            }
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancelar</button>
                            <button type="button" class="btn btn-primary" onClick={(e) => this.salvarEvento()}>Salvar</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
