import React, { Component } from 'react';

export class Rodape extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ano: new Date().getFullYear(),
        }
    }
    render() {
        return (
            <div>
                <section data-bs-version="5.1" class="footer3 cid-sZYRaSds7b" once="footers" id="footer3-q">
                    <div class="container">
                        <div class="media-container-row align-center mbr-white">
                            <div class="row row-links no-print">
                                <ul class="foot-menu">
                                    <li class="foot-menu-item mbr-fonts-style display-7">Sobre nós</li><li class="foot-menu-item mbr-fonts-style display-7">Cursos &amp; Treinamentos</li><li class="foot-menu-item mbr-fonts-style display-7">Contato</li><li class="foot-menu-item mbr-fonts-style display-7">Área do Usuário</li><li class="foot-menu-item mbr-fonts-style display-7">Início</li></ul>
                            </div>

                            <div class="row row-copirayt">
                                <p class="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">
                                    CNPJ: 33.742.886/0001-06 - Razão Social: D. S. COMÉRCIO IMPORTAÇÃO E SERVIÇOS PUBLICITÁRIOS LTDA
                                </p>
                            </div>

                            <div class="row row-copirayt">
                                <p class="mbr-text mb-0 mbr-fonts-style mbr-white align-center display-7">
                                    © Copyright {this.state.ano} MegaGold. Todos os direitos reservados.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>

            </div >
        );
    }
}
