import cookie from 'react-cookies';
const DeleteMembro = (idMembro) => {
    const token = cookie.load('access_token');

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
      
    };
    return fetch('api/membro/delete/'+idMembro, requestOptions)

};

export default DeleteMembro;