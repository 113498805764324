
import { Button } from 'bootstrap';
import * as moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { toast } from 'react-toastify'
import GetEvento from '../Api/GetEvento';
import GetInscricao from '../Api/GetInscricao';
import PostEvento from '../Api/PostEvento';
import PostInscricao from '../Api/PostInscricao';
import { BuscarMembro } from '../Tools/BuscarMembro';
import { Obrigatorio } from '../Tools/Obrigatorio';

export class InscreverEvento extends Component {
    static displayName = InscreverEvento.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            inscrito: false,

        }
    }

    componentDidMount() {
        this.carregarEvento();
    }

    carregarEvento() {
        GetEvento(this.props.idEvento, (d) => this.setState({ evento: d, preco: d.precos[0].id }, this.carregarInscricao))
    }

    carregarInscricao() {
        GetInscricao(this.props.idEvento, (d) => {
            this.setState({ loading: false, inscrito: true });
        }, (t) => {
            this.setState({ loading: false })
        })
    }

    inscrever() {
        PostInscricao(this.props.idEvento, this.state.preco, (t) => this.setState({ inscrito: true }));
    }


    render() {

        return (
            <div class="modal fade" id="inscreverModal" tabindex="-1" aria-labelledby="inscreverModal" aria-hidden="true">
                <div class="modal-dialog  modal-lg h-auto">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Inscreva-se</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            {this.state.loading ? "Carregando..." :
                                <>{
                                    !this.state.inscrito ?
                                        <div class="container">
                                            <div class="row justify-content-center">
                                                <div class=" mbr-form" data-form-type="formoid">

                                                    <div class="row">
                                                        <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                                            <h7 className="display-7">Curso</h7>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                            <input name="type" class="form-control" disabled={true} value={this.state.evento.nome} />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                                            <h7 className="display-7">Data</h7>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col form-group mb-3" data-for="name">
                                                            <input name="type" class="form-control" disabled={true} value={moment(this.state.evento.dataInicio).format("L")} />
                                                        </div>
                                                        <div class="col form-group mb-3" data-for="name">
                                                            <input name="type" class="form-control" disabled={true} value={moment(this.state.evento.dataFim).format("L")} />
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                                            <h7 className="display-7">Valor</h7>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                            <select className="form-control" value={this.state.preco} onChange={(e) => this.setState({ preco: e.target.value })}>
                                                                {this.state.evento.precos.map((p, i) => <option key={i} value={p.id} >R${p.valor.toFixed(2)} ({p.descricao})</option>)}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                                            <h7 className="display-7">Forma de pagamento</h7>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                            <select className="form-control">
                                                                <option>Deposito Bancário</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                            <textarea className="form-control" placeholder={"Adicionar comentário a inscrição..."}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div class="container">
                                            <div class="row justify-content-center">
                                                <p> Parabéns, você se inscreveu neste curso, aguarde o nosso contato ou entre em<a href="https://wa.me/556993116198" target="_blank"> contato conosco.</a></p>
                                                <p> Para definir os participantes acesse <a href={"/inscricao/" + this.props.idEvento} > a página da inscrição</a> </p>
                                            </div>
                                        </div>
                                }</>
                            }

                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                            {
                                !this.state.inscrito ? <button type="button" class="btn btn-success" onClick={(e) => this.inscrever()} >Inscrever-se</button>
                                    : null
                            }
                        </div>

                    </div>
                </div>

            </div>
        );
    }
}
