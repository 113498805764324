
import { Button } from 'bootstrap';
import React, { Component } from 'react';
import GetUsuario from '../Api/GetUsuario';
import { toast } from 'react-toastify'
import { Eventos } from './Eventos';
import { Link, Redirect } from 'react-router-dom';
import GetEventos from '../Api/GetEventos';

export class PainelUsuario extends Component {
    static displayName = PainelUsuario.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            eventos: [],
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.carregarUsuario();
    }

    carregarUsuario() {
        GetUsuario().then(r => {
            if (r.ok) {
                r.json().then(d => this.setState({ usuario: d }, () => this.carregarEventos()))
            } else {
                r.text().then(t => toast.error(t));
                this.setState({ sair: true })
            }
        })
    }
    carregarEventos() {
        GetEventos(true, (d) => this.setState({ eventos: d, loading: false }), (t) => toast.error(t));
    }

    render() {
        if (this.state.sair)
            return <Redirect to="/sair" />
        if (this.state.loading)
            return "Carregando..."
        return (
            <div>
                <section data-bs-version="5.1" class="header19 cid-t0bv8D4l9k mbr-fullscreen" id="header19-0">
                    <div class="container">
                        <div class="media-container">
                            <div class="col-md-12 align-center">
                                <h1 class="mbr-section-title mbr-white mbr-bold mbr-fonts-style mb-3 display-1">{this.state.usuario.nome}</h1>
                                <p class="mbr-text mbr-white mbr-fonts-style display-7">
                                    Bem Vindo ao seu perfil, aqui estão disponíveis todos os seus cursos, concluídos, em andamento ou inscrições pendentes</p>

                                <div class="mbr-section-btn align-center"><Link to={"/sair"} className="btn btn-danger display-4">Sair</Link></div>


                                <div class="row justify-content-center">
                                    <div class="col-12 col-md-6 col-lg-3">
                                        <div class="card-wrapper">
                                            <div class="card-box align-center">
                                                <span class="mbr-iconfont mobi-mbri-growing-chart mobi-mbri"></span>
                                                <h4 class="card-title align-center mbr-black mbr-fonts-style display-7">
                                                    <strong>Cursos</strong></h4>
                                                <h4 class="card-title align-center mbr-black mbr-fonts-style display-7">
                                                    <strong>{ this.state.eventos.length}</strong></h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-3">
                                        <div class="card-wrapper">
                                            <div class="card-box align-center">
                                                <span class="mbr-iconfont mobi-mbri-star mobi-mbri"></span>
                                                <h4 class="card-title align-center mbr-black mbr-fonts-style display-7">
                                                    <strong>Concluídos</strong></h4>
                                                <h4 class="card-title align-center mbr-black mbr-fonts-style display-7">
                                                    <strong>{this.state.eventos.filter(e=>e.encerrado).length}</strong></h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-3">
                                        <div class="card-wrapper">
                                            <div class="card-box align-center">
                                                <span class="mbr-iconfont mobi-mbri-letter mobi-mbri"></span>


                                                <h4 class="card-title align-center mbr-black mbr-fonts-style display-7">
                                                    <strong>Certificados</strong></h4>
                                                <h4 class="card-title align-center mbr-black mbr-fonts-style display-7">
                                                    <strong>{this.state.eventos.filter(e => e.encerrado).length}</strong></h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 col-lg-3">
                                        <div class="card-wrapper">
                                            <div class="card-box align-center">
                                                <span class="mbr-iconfont mobi-mbri-shopping-bag mobi-mbri"></span>
                                                <h4 class="card-title align-center mbr-black mbr-fonts-style display-7">
                                                    <strong>Pendentes</strong></h4>
                                                <h4 class="card-title align-center mbr-black mbr-fonts-style display-7">
                                                    <strong>{this.state.eventos.filter(e => e.inscricao.situacao == 0 && !e.encerrado).length}</strong></h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Eventos meusCursos />

            </div >
        );
    }
}
