
import * as moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import DeleteInscricaoAdmin from '../Api/DeleteInscricaoAdmin';
import GetEventos from '../Api/GetEventos';
import GetInscricoes from '../Api/GetInscricoes';
import PutInscricao from '../Api/PutInscricao';
import { CSVLink } from "react-csv";
import GetUsuarios from '../Api/GetUsuarios';
import cookie from 'react-cookies';

export class PainelAdminInscricoes extends Component {
    static displayName = PainelAdminInscricoes.name;
    constructor(props) {
        super(props);
        this.timeout = 0;
        this.state = {
            loading: true,
            inscricoes: [],
            idCurso: 0,
            usuarios: [],
            usuarioId: null,
            token: cookie.load('access_token'),
        }
    }

    componentDidMount() {
        this.carregarCursos();
    }


    carregarCursos = () => {
        GetEventos(false, (e) => this.setState({ eventos: e, loading: false }), (e) => console.log(e));
    }

    carregarUsuarios = () => {
        GetUsuarios().then(r => {
            if (r.ok) {
                r.json().then(data => {
                    this.setState({ usuarios: data, loading: false }, this.carregarCursos);
                })
            }
        })
    }

    carregarInscricoes = (e) => {

        GetInscricoes(this.state.idCurso, (data) => {
            this.setState({ inscricoes: data }, this.carregarUsuarios);
        }, (e) => toast.error(e));


    }


    atualizarSituacaoInscricao = (e, inscricao) => {
        inscricao[e.target.name] = parseInt(e.target.value);
        PutInscricao(inscricao, (t) => {
            toast.success(t);
            this.carregarInscricoes();
        }, (e) => toast.error(e));
    }
    atualizarCertificado = (e, index, inscricao) => {

        inscricao[e.target.name] = e.target.value;

        let inscricoes = [...this.state.inscricoes];

        let item = { ...inscricoes[index] };

        item[e.target.name] = e.target.value;

        inscricoes[index] = item;

        this.setState({ inscricoes }, () => {


            if (this.timeout) clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                PutInscricao(inscricao, (t) => {
                    toast.success(t);
                    this.carregarInscricoes();
                }, (e) => toast.error(e));
            }, 300);
        });
    }

    excluirInscricao = (idInscricao) => {
        if (window.confirm("Tem certeza que deseja excluir a inscrição?")) {
            DeleteInscricaoAdmin(idInscricao, (e) => {
                toast.success(e);
                this.carregarInscricoes();
            }, (e) => toast.error(e));
        }
    }

    getStatus = (idStatus) => {
        switch (idStatus) {
            case 0: return "Aguardando pagamento";
            case 1: return "Aprovado";
            case 2: return "Cancelado";
        }
    }

    getCsvData = () => {
        const csvData = [[
            'Id',
            'Data',
            'Usuário',
            'Cidade',
            'E-mail',
            'Telefone',
            'Valor',
            'Situação',
        ]];
        let i;
        for (i = 0; i < this.state.inscricoes.length; i += 1) {
            csvData.push([
                `${this.state.inscricoes[i].id}`,
                `${moment(this.state.inscricoes[i].dataInscricao).format("DD/MM/YYYY hh:mm")}`,
                `${this.state.inscricoes[i].usuario.nome}`,
                `${this.state.inscricoes[i].usuario.cidade}`,
                `${this.state.inscricoes[i].usuario.email}`,
                `${this.state.inscricoes[i].usuario.telefone}`,
                `${this.state.inscricoes[i].preco ? this.state.inscricoes[i].preco.valor : 0}`,
                `${this.getStatus(this.state.inscricoes[i].situacao)}`,
            ]);
        }
        return csvData;
    };

    gerarInscricao = () => {

        fetch('api/evento/usuario/' + this.state.idCurso + "/" + this.state.usuarioId, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + this.state.token,
            },
        }).then(r => {
            if (r.ok) {
                r.text().then(e => toast.success(e));
                this.carregarInscricoes();
            } else {
                r.text().then(e => toast.error(e));
            }
        });
    }

    render() {
        if (this.state.loading) {
            return "Carregando...";
        }
        return <section data-bs-version="5.1" className="header19 cid-t0bvGWXrL2" style={{ paddingTop: 1 + "rem" }} id="header19-0">
            <div className="container-flex">

                <div className="row justify-content-center mt-4">
                    <div className="mbr-form" data-form-type="formoid">
                        <div className="container">
                            <div className="row">
                                <div className="col-3 form-group" data-for="name">
                                    <h6 className="display-6 ">Curso:</h6>
                                </div>
                                <div className="col-8 form-group" data-for="name">
                                    <h6 className="display-6">
                                        <select className="form-control" onChange={(e) => this.setState({ idCurso: e.target.value }, this.carregarInscricoes)}>
                                            <option value={0}>Selecione</option>
                                            {
                                                this.state.eventos.map((e) => <option key={e.id} value={e.id}>{e.nome}{e.encerrado ? " - [Encerrado]" : null}</option>)
                                            }
                                        </select>
                                    </h6>
                                </div>
                                <div className="row mt-auto" >
                                    <div className="col text-end">
                                        <h6 className="display-7  ">Inscrever:</h6>
                                    </div>
                                    <div className="col display-6">
                                        <select className="form-control" onChange={(e) => this.setState({ usuarioId: e.target.value })}>
                                            <option value={0}>Selecione</option>
                                            {
                                                this.state.usuarios.map((e) => <option key={e.id} value={e.id}>{e.nome}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className="col">
                                        <input type="button" className="btn btn-small btn-primary" value="➕" onClick={this.gerarInscricao} />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col form-group" data-for="name">
                                    <h6 className="display-6">Inscrições ({this.state.inscricoes.length}) </h6>
                                </div>
                                <div className="col-2 form-group text-center" data-for="name">

                                    <CSVLink data={this.getCsvData()} target="_blank" className="btn btn-primary" separator=";" >Exportar</CSVLink>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col form-group mb-3" data-for="name">
                                <div className="table-responsive" data-for="name">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Data</th>
                                                <th scope="col">Usuário</th>
                                                <th scope="col">Cidade</th>
                                                <th scope="col">Valor</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Certificado</th>
                                                <th scope="col">Excluir</th>
                                                <th scope="col">Imprimir</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.inscricoes.map((i, index) =>
                                                    <tr >
                                                        <th scope="row">{i.id}</th>
                                                        <td title={moment(i.dataInscricao).format("DD/MM/YYYY HH:mm")}>{moment(i.dataInscricao).format("DD/MM/YYYY")}</td>
                                                        <td>{i.usuario.nome}</td>
                                                        <td title={i.usuario.cidade}>{!i.usuario.cidade || i.usuario.cidade.length < 20 ? i.usuario.cidade : i.usuario.cidade.slice(0, 20) + "..."}</td>
                                                        <td>{i.preco ? i.preco.valor : 0}</td>
                                                        <td>
                                                            <select className="form-control" name="situacao" value={i.situacao} onChange={(e) => {
                                                                this.atualizarSituacaoInscricao(e, i);
                                                            }
                                                            }>
                                                                <option value={0}>Aguardando pagamento</option>
                                                                <option value={1}>Aprovada</option>
                                                                <option value={2}>Cancelada</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <a className="btn btn-success" href={"http://www.megagold.com.br/static/media/certificados/" + i.evento.id + "/" + i.usuario.id + ".pdf"} target="_blank" title="Certificado">📄</a>
                                                        </td>
                                                        <td>
                                                            <input type="button" className="btn btn-small btn-danger" value="❌" onClick={(e) => this.excluirInscricao(i.id)} title="Excluir" />
                                                        </td>
                                                        <td>
                                                            <Link target="_blank" to={"/inscricao/imprimir/" + i.id} className="btn btn-small btn-secondary" title="Imprimir">🖨</Link>
                                                        </td>

                                                    </tr>
                                                )

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    }
}
