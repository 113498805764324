
import { Button } from 'bootstrap';
import React, { Component } from 'react';
import GetUsuario from '../Api/GetUsuario';
import { toast } from 'react-toastify';
import './mbr-additional.css';
import GetMembros from '../Api/GetMembros';
import DeleteMembro from '../Api/DeleteMembro';
import GetMembrosEvento from '../Api/GetMembrosEvento';
import cookie from 'react-cookies';

export class ListarEquipe extends Component {
    static displayName = ListarEquipe.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            membros: [],
            usuario: cookie.load('usuario'),
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.carregarMembros();
    }

    carregarMembros() {
        if (this.props.idEvento) {
            GetMembrosEvento(this.props.idEvento).then(r => {
                if (r.ok) {
                    r.json().then(d => this.setState({ loading: false, membros: d }))
                } else {
                    r.text().then(t => toast.error(t));
                }
            })
        } else {
            GetMembros().then(r => {
                if (r.ok) {
                    r.json().then(d => this.setState({ loading: false, membros: d }))
                } else {
                    r.text().then(t => toast.error(t));
                }
            })
        }
    }
    excluirMembro(idMembro) {
        DeleteMembro(idMembro).then(r => {
            if (r.ok) {
                r.text().then(t => toast.success(t));
                this.carregarMembros();
            } else {
                r.text().then(t => toast.error(t));
            }
        });
    }
    render() {
        if (this.state.loading)
            return "Carregando..."
        return (
            <div>

                <section data-bs-version="5.1" class="team1 cid-sZZY9i2hM3" id="team1-11">



                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="col-12">
                                <h3 class="mbr-section-title mbr-fonts-style align-center mb-4 display-2">
                                    <strong>Equipe</strong></h3>

                            </div>


                            {
                                this.state.membros.map((m) =>
                                    <div class="col-sm-6 col-lg-4">
                                        <div class="card-wrap">
                                            <div class="image-wrap">
                                                <img src={m.imagem} alt="Mobirise Website Builder" />
                                            </div>
                                            <div class="content-wrap">
                                                <h5 class="mbr-section-title card-title mbr-fonts-style align-center m-0 display-5">
                                                    <strong>{m.nome}</strong>
                                                </h5>
                                                <h6 class="mbr-role mbr-fonts-style align-center mb-3 display-4">
                                                    <strong>{m.cargo}</strong>
                                                </h6>
                                                <p class="card-text mbr-fonts-style align-center display-7" dangerouslySetInnerHTML={{ __html: m.descricao }}/>
                                                  
                                                {this.state.usuario && this.state.usuario.admin ?
                                                    <div class="social-row display-7">
                                                        <input type="button" className="btn btn-danger" value="Excluir" onClick={(e) => this.excluirMembro(m.id)} />

                                                    </div>
                                                    :null
                                                }

                                            </div>
                                        </div>
                                    </div>
                                )

                            }


                        </div>
                    </div>
                </section>




            </div >
        );
    }
}
