
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import DeleteUsuario from '../Api/DeleteUsuario';
import GetUsuarios from '../Api/GetUsuarios';
import ResetUsuario from '../Api/ResetUsuario';
import { Link } from 'react-router-dom';

export class PainelAdminUsuarios extends Component {
    static displayName = PainelAdminUsuarios.name;
    constructor(props) {
        super(props);
        this.timeout = 0;
        this.state = {
            loading: true,
            inscricoes: [],
        }
    }

    componentDidMount() {
        this.carregarUsuarios();
    }

    carregarUsuarios = () => {
        GetUsuarios().then(r => {
            if (r.ok) {
                r.json().then(data => {
                    this.setState({ usuarios: data, loading: false }, this.carregarCursos);
                })

            }
        })
    }







    excluirUsuario = (idUsuario) => {
        if (window.confirm("Tem certeza que deseja excluir o cadastro do usuário?")) {
            DeleteUsuario(idUsuario).then(r => {
                if (r.ok) {
                    r.text().then(e => toast.success(e));
                    this.carregarUsuarios();
                } else {
                    r.text().then(e => toast.error(e));
                }
            })
        }
    }
    resetarSenha = (idUsuario) => {
        if (window.confirm("Tem certeza que deseja redefinir a senha do usuário?")) {
            ResetUsuario(idUsuario).then(r => {
                if (r.ok) {
                    r.text().then(e => toast.success(e));
                    this.carregarUsuarios();
                } else {
                    r.text().then(e => toast.error(e));
                }
            })
        }
    }


    render() {
        if (this.state.loading) {
            return "Carregando...";
        }
        return <section data-bs-version="5.1" class="header19 cid-t0bvGWXrL2" style={{ paddingTop: 1 + "rem" }} id="header19-0">
            <div class="container-flex">
                <div class="row justify-content-center mt-4">
                    <div class="mbr-form" data-form-type="formoid">
                        <div class="row">
                            <div class="col-12 form-group text-center" data-for="name">
                                <h6 className="display-6">Usuários Cadastrados ({this.state.usuarios.length})</h6>
                                <Link to="/admin/cadastrar" className="btn btn-small btn-primary"  >➕</Link>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col form-group mb-3" data-for="name">
                                <div class="table-responsive" data-for="name">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Nome</th>
                                                <th scope="col">Pessoa</th>
                                                <th scope="col">Instituição</th>
                                                <th scope="col">Cargo</th>
                                                <th scope="col">CPF/CNPJ</th>
                                                <th scope="col">E-mail</th>
                                                <th scope="col">Telefone</th>
                                                <th scope="col">Excluir</th>
                                                <th scope="col">Senha</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.usuarios.map((u) =>
                                                    <tr key={u.id}>
                                                        <th scope="row">{u.id}</th>
                                                        <td>{u.nome}</td>
                                                        <td>{u.tipoPessoa}</td>
                                                        <td>{u.instituicao}</td>
                                                        <td>{u.cargo}</td>
                                                        <td>{u.tipoPessoa === "F" ? u.cpf : u.cnpj}</td>
                                                        <td>{u.email}</td>
                                                        <td>{u.telefone}</td>
                                                        <td><input type="button" className="btn btn-small btn-danger" value="❌" title="Excluir" onClick={(e) => this.excluirUsuario(u.id)} /></td>
                                                        <td><input type="button" className="btn btn-small btn-danger" value="🔑" title="Resetar senha" onClick={(e) => this.resetarSenha(u.id)} /></td>
                                                    </tr>
                                                )


                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    }
}
