import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home/Home';

import './mobirise/mobirise2.css'

import './mobirise/bootstrap/bootstrap.min.css'
import './mobirise/bootstrap/bootstrap-grid.min.css'
import './mobirise/bootstrap/bootstrap-reboot.min.css'

import './mobirise/socicon/css/styles.css'
import './mobirise/theme/style.css'
import './mobirise/font1.css'
import './mobirise/font2.css'
import './mobirise/mbr-additional.css'
import './custom.css'


import 'react-toastify/dist/ReactToastify.min.css';


import TokenProvider from './components/Store/Store';
import { PainelUsuario } from './components/Usuario/PainelUsuario';
import { PainelAdminUsuarios } from './components/Usuario/PainelAdminUsuarios';
import { PainelAdminInscricoes } from './components/Usuario/PainelAdminInscricoes';
import { Cadastrar } from './components/Usuario/Cadastrar';
import { toast } from 'react-toastify';
import { Eventos } from './components/Usuario/Eventos';
import { Evento } from './components/Evento/Evento';
import { CadastrarMembro } from './components/Evento/CadastrarMembro';
import { BrowserRouter } from 'react-router-dom';
import Logoff from './components/Login/Logoff';
import { CadastrarParticipante } from './components/Usuario/CadastrarParticipante';
import { ImprimirInscricao } from './components/Usuario/ImprimirInscricao';
import { Redirecionar } from './components/Evento/Redirecionar';
import { CadastrarAdmin } from './components/Usuario/CadastrarAdmin';





export default class App extends Component {
    static displayName = App.name;
    componentDidMount() {
        toast.configure();

    }

    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Layout>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/sair' component={Logoff} />

                        <Route exact path='/painel'>
                            <TokenProvider>
                                <PainelUsuario />
                            </TokenProvider>
                        </Route>

                        <Route path='/admin/usuarios'>
                            <TokenProvider>
                                <PainelAdminUsuarios />
                            </TokenProvider>
                        </Route>
                        <Route path='/admin/inscricoes'>
                            <TokenProvider>
                                <PainelAdminInscricoes />
                            </TokenProvider>
                        </Route>
                        <Route exact path='/admin/cadastrar'>
                            <TokenProvider>
                               <CadastrarAdmin />
                            </TokenProvider>
                        </Route>

                        <Route exact path='/cadastrarmembro'>
                            <TokenProvider>
                                <CadastrarMembro />
                            </TokenProvider>
                        </Route>

                        <Route exact path='/cadastrar' component={Cadastrar} />
                        <Route exact path='/cadastrar/:idEvento' component={Cadastrar} />
                        <Route exact path='/eventos' component={Eventos} />
                        <Route exact path='/eventos/:idEvento/' component={Evento} />
                        <Route exact path='/eventos/:idEvento/:inscrever' component={Evento} />
                        <Route exact path='/redirecionar/evento/:url' component={Redirecionar} />

                        <Route path='/inscricao/'>
                            <TokenProvider>
                                <Route exact path='/inscricao/:idEvento' component={CadastrarParticipante} />
                                <Route exact path='/inscricao/imprimir/:idInscricao' component={ImprimirInscricao} />
                            </TokenProvider>
                        </Route>




                    </Layout>
                </Switch>
            </BrowserRouter>
        );
    }
}
