import cookie from 'react-cookies';
const GetMembrosEvento = (idEvento) => {
    const token = cookie.load('access_token');
       
        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            }

        };

        return fetch('api/membro/'+idEvento,requestOptions)

    
};

export default GetMembrosEvento;