import React, { useState, useCallback } from 'react';
import { Redirect } from 'react-router';
import { useCookies } from 'react-cookie'
import './LoginCss.css';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

export default function Logar(props) {

    const [cookies, setCookie] = useCookies(['access_token', 'usuario'])
    const [usuario, setUsuario] = useState("");

    const [senha, setSenha] = useState("");
    const [redirect, setRedirect] = useState(false);
    const [viewLogin, setViewLogin] = useState(false);
   



    function handleErrors(response) {

        toast.configure();
        if (!response.ok) {
            response.text().then(t => toast.error(t));
        } else {



            response.json().then(data => {
                setCookie('usuario', data, { path: '/' });
                setCookie('access_token', data.token, { path: '/' });

                if (data.admin) {                  
                    window.location.reload();
                }
            }).then(setRedirect(true));



        }
        return response;
    }

    var initialState = (event) => {

        event.preventDefault();
        event.stopPropagation();

        const requestOptions = {

            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                nome: usuario,
                senha: senha,
            })
        };




        fetch('api/usuario/login', requestOptions).then(handleErrors);


    };

    if (props.user && props.token) {
        setCookie('usuario', props.user, { path: '/' });
        setCookie('access_token', props.token, { path: '/' });
       
        return <Redirect push to="/" />;

    }

    if (redirect || cookies.usuario) {
        return <Redirect push to="/painel" />;
    }

    return (
        <div className="header19 cid-t0bv8D4l9k mbr-fullscreen" runat="server" style={{ alignItems: 'normal' }}>
            <div className="container">
                <div className="row">
                    <div className="offset-1 col-10 offset-lg-4 col-10 col-lg-4">

                        <div className="account-wall form">
                            <img className="profile-img" src={require("../images/photo.png")}
                                alt=""
                                onClick={() => setViewLogin(true)}
                            />
                            <h1 className="text-center login-title">Entre agora ou <Link to={"/cadastrar"}>inscreva-se</Link></h1>


                            <div className="form-signin text-center" >

                                <input name="usuario" type="text" placeholder="E-mail ou telefone do usuário" className="form-control input-md" autoFocus  value={usuario} onChange={(e) => setUsuario(e.target.value)} />
                                <input  name="senha" type="password" placeholder="Senha" className="form-control input-md" required value={senha} onChange={(e) => setSenha(e.target.value)} />
                            </div>
                            <div className="form-signin text-center" >
                                <input type="button" name="_btnEntrar" className="btn btn-primary" value="Entrar" onClick={initialState} disabled={!usuario || !senha }/>

                            </div>
                            <div className="form-signin text-center" >
                                <Link to={"/cadastrar"}>Esqueceu sua senha?</Link>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}
