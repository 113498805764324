
import { Button } from 'bootstrap';
import React, { Component } from 'react';

import { toast } from 'react-toastify'
import PostMembro from '../Api/PostMembro';
import { ListarEquipe } from './ListarEquipe';

export class CadastrarMembro extends Component {
    static displayName = CadastrarMembro.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);

    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    validar() {
        if (!this.state.nome
            || !this.state.cargo
            || !this.state.imagem
            || !this.state.descricao
        ) {
            toast.error("Todos os campos são obrigatórios");
            return false;
        } else {

            PostMembro(this.state).then(r => {
                if (r.ok) {
                    r.text().then(t => toast.success(t));
                } else {
                    r.text().then(t => toast.error(t));
                }
            })


            return true;
        }
    }

    render() {

        return (



            <div class="row justify-content-center mt-4">
                <div class="offset-lg-3 mbr-form" data-form-type="formoid">
                    <div class="row">
                        <div class="col-12 form-group" data-for="name">
                            <h6 className="display-6">Nome completo do membro da equipe</h6>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                            <input type="text" name="nome" placeholder="Nome de identificação do evento" class="form-control" value={this.state.nome} onChange={this.onChange} />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                            <h6 className="display-7">Cargo</h6>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                            <input type="text" name="cargo" placeholder="Informe o cargo ou posição do membro da equipe" class="form-control" value={this.state.cargo} onChange={this.onChange} />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                            <h6 className="display-7">Imagem</h6>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                            <input type="text" name="imagem" placeholder="Informe o endereço da imagem .png ou .jpg" class="form-control" value={this.state.imagem} onChange={this.onChange} />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg col-md-12 col-sm-12 form-group" data-for="name">
                            <h7 className="display-7">Descrição</h7>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                            <textarea className="form-control" placeholder="Dê mais informações sobre o membro" name="descricao" value={this.state.descricao} onChange={this.onChange} />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-lg col-md-12 col-sm-12 form-group" data-for="name">
                            <input type="button" className="btn btn-success" value="Salvar" onClick={(e) => this.validar() }/>
                        </div>
                    </div>




                </div>
                <ListarEquipe />
            </div>


        );
    }
}
