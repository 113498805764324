import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import cookie from 'react-cookies';
import axios from 'axios';

export class BuscarMembro extends Component {
    static displayName = BuscarMembro.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.timeout = 0;

        this.state = {
            loading: true,
            pesquisa: "",
            itens: [],
            token: cookie.load('access_token'),
            idPadrao: this.props.idPadrao,
        }

    }

    componentDidMount() {
        this._isMounted = true;
        if (this.state.idPadrao) {
            this.getItem(this.state.idPadrao);
        } else
            this.getItens("");
    }

    componentDidUpdate() {
        if (this.props.idPadrao) {
            if (this.state.idPadrao != this.props.idPadrao) {
                this.state.idPadrao = this.props.idPadrao;
                this.setState({ idPadrao: this.props.idPadrao }, this.componentDidMount)
            }


        }
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
    async getItem(id) {
        const response = await axios.get('api/membro/', {
            params: {
                id: id,
            },
            headers: {
                Authorization: 'Bearer ' + this.state.token
            }
        });

        const data = response.data;
        if (this._isMounted) {
            this.setState({ pesquisa: data.nome });
            this.props.recebeItem(data);
        }
    }

    async getItens(pesquisa) {
        this.setState({ loading: true });
       
        const response = await axios.get('api/membro/', {
            params: {              
                pesquisa: pesquisa,
            },
            headers: {
                Authorization: 'Bearer ' + this.state.token
            }
        });

        var data = response.data;
        if (data.length == 0 && this.state.pesquisa != "") {
            data = [{ nome: "", id: "" }]
        }

        if (this._isMounted)
            this.setState({ itens: data, loading: false });
    }

    handlerItemInput(value) {
        this.setState({ pesquisa: value });

        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {


            this.getItens(value);
            if (value == "") {
                this.props.recebeItem(null);
                this.setState({ itens: [] });
            }
        }, 300);


    }

    renderLista(item, isHighlighted) {
        if (!this.state.loading) {
            if (item.nome)
                return (
                    <div key={item.id} style={{ background: isHighlighted ? 'lightgray' : 'white', zIndex: 1, padding: 5 + "px" }} className="border-bottom itemlista">
                        <div><strong>{item.nome}</strong></div>                       
                    </div>
                )
            else return (
                <div key={item.id} style={{ background: 'white', zIndex: 1, padding: 5 + "px" }} className="border-bottom">
                    <div>Nenhum resultado disponível</div>
                </div>
            )
        } else {
            return <div></div>
        }
    }

    render() {

        return (
            <div className="input-group">          
                <Autocomplete
                    value={this.state.pesquisa}
                    class="form-control"
                    getItemValue={(item) => item.nome}
                    items={this.state.itens}

                    renderItem={(item, isHighlighted) => this.renderLista(item, isHighlighted)}

                    onChange={(e) => this.handlerItemInput(e.target.value)}
                    onSelect={(val, item) => {
                        if (item.nome) {
                            this.setState({ pesquisa: val });
                            this.props.recebeItem(item);
                        }
                    }}

                    menuStyle={{
                        borderRadius: '3px',
                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                        background: 'rgba(255, 255, 255, 0.9)',
                        padding: '2px 0',
                        zIndex: '3',
                        fontSize: '90%',
                        position: 'fixed',
                        overflow: 'auto',
                        maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                    }}
                    inputProps={
                        {
                            className: "form-control noborder " + (this.state.loading ? " input " : ""),
                            placeholder: "Digite para pesquisar",
                            disabled: this.props.disabled ? this.props.disabled : false
                        }}
                    wrapperStyle={{ width: 100 + '%', flex: "1 1" }}
                    selectOnBlur={false}
                />
                {this.props.disabled && this.props.disabled == true ? null : (
                    <div className="input-group-append">
                        <button className="btn btn-outline-secondary" type="button" onClick={(e) => this.handlerItemInput("")}>Limpar</button>
                    </div>
                )}
            </div>
        );
    }
}
