import React, { createContext, useState, useContext } from "react";
import { useCookies } from 'react-cookie';
import Logar from "../Login/Logar";


const TokenContext = createContext();

export default function TokenProvider({ children }) {
    const [token, setToken] = useState(null);

    const [cookies, setCookie] = useCookies(['access_token','usuario'])
    
    if (cookies.access_token && cookies.usuario) {      
        return (
            <TokenContext.Provider value={{ token, setToken }}>
                {children}
            </TokenContext.Provider>
        )
    }else
        return (

            <TokenContext.Provider value={{ token, setToken }}>
                <Logar/>
            </TokenContext.Provider>
        )

}



export function useDefault() {
    const context = useContext(TokenContext);
    const { token, setToken } = context;
    return { token, setToken };

}
