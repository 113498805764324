import React, { useState,useCallback} from 'react';
import { Redirect } from 'react-router';
import cookie from 'react-cookies';


export default function Logoff() {


 
        cookie.remove('access_token', { path: '/' });
        cookie.remove('usuario', { path: '/' });


        return <Redirect push to="/painel" />;
    
}

    