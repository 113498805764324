
const PostUsuario = (usuario) => {
   

    const requestOptions = {
        method: 'POST',
        headers: {         
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(usuario)
    };
    return fetch('api/usuario', requestOptions)

};

export default PostUsuario;