import * as moment from 'moment';
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import GetInscricaoImpressao from '../Api/GetInscricaoImpressao';

export class ImprimirInscricao extends Component {
    static displayName = ImprimirInscricao.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            idInscricao: this.props.match.params.idInscricao,
        }

    }
    componentDidMount() {

        GetInscricaoImpressao(this.state.idInscricao, (i) => this.setState({ inscricao: i, loading: false }, () => console.log(this.state.inscricao)), (e) => toast.error(e));
    }


    render() {

        return this.state.loading ? "carregando..." : <>

            <section data-bs-version="5.1" class="header19 cid-t0bvGWXrL2" style={{ paddingTop: 1 + "rem" }} id="header19-0">
                <div class="container">
                    <div class="row justify-content-center mt-4">
                        <div class="col">
                            <div class="row">
                                <div class="col-8">
                                    <input type="button" value="Imprimir" className="btn btn-secondary btn-small no-print" onClick={() => window.print()} />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-8 mt-auto">
                                    <h1>Comprovante de Inscrição #{this.state.inscricao.idInscricao}</h1>
                                </div>
                                <div class="col-2">
                                    <img src={require('../../images/logo.png')} alt="Mobirise Website Builder" />
                                </div>

                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <h2>Nome Completo</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h4 className="text-muted">{this.state.inscricao.usuario.nome}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <h2>CPF</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h4 className="text-muted">{this.state.inscricao.usuario.cpf}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <h2>Orgão</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h4 className="text-muted">{this.state.inscricao.usuario.instituicao}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <h2>Cargo</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h4 className="text-muted">{this.state.inscricao.usuario.cargo}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <h2>E-Mail</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h4 className="text-muted">{this.state.inscricao.usuario.email}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <h2>Telefone</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h4 className="text-muted">{this.state.inscricao.usuario.telefone}</h4>
                                        </div>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <h2>Curso</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h4 className="text-muted">{this.state.inscricao.evento.nome}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <h2>Data</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h4 className="text-muted">{moment(this.state.inscricao.evento.dataInicio).format("DD/MM/YYYY")} a {moment(this.state.inscricao.evento.dataFim).format("DD/MM/YYYY")}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <div class="row">
                                        <div class="col">
                                            <h2>Local</h2>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <h4 className="text-muted">{this.state.inscricao.evento.local}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4 text-center">
                        <div class="col">
                            <div class="row">
                                <div class="col">
                                    <h5>D. S. COMÉRCIO IMPORTAÇÃO E SERVIÇOS PUBLICITÁRIOS LTDA</h5>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <h5 className="text-muted">33.742.886/0001-06</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    }
}