
import { Button } from 'bootstrap';
import React, { Component } from 'react';
import GetUsuario from '../Api/GetUsuario';
import { toast } from 'react-toastify';
import './mbr-additional.css';
import { ListarEquipe } from './ListarEquipe';
import GetEvento from '../Api/GetEvento';
import moment from 'moment';
import 'moment/locale/pt-br';
import { InscreverEvento } from './InscreverEvento';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';

export class Evento extends Component {
    static displayName = Evento.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            idEvento: this.props.match.params.idEvento,
            inscrever: this.props.match.params.inscrever,
            usuario: cookie.load('usuario'),
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        moment.locale('pt-br');
        this.carregarEvento();
       

    }

    carregarEvento() {
        GetEvento(this.state.idEvento, (evento) => {
            this.setState({ loading: false, evento: evento }, this.abrirInscricao);
            window.history.replaceState(null, evento.url, "/redirecionar/evento/"+evento.url);
        }, (t) => toast.error(t));

    }

    abrirInscricao() {
        if (this.state.inscrever && this.state.usuario) {

            this.btnInscrever.click();
        }
    }


    render() {
        if (this.state.loading)
            return "Carregando...";
        const evento = this.state.evento;
        const googleUrl = "https://www.google.com/maps/embed/v1/place?key=AIzaSyCNveGQ9bfpKFwWzQLLftrR9hNiHwdqQG8&q=" + evento.local;
        return (

            <div>

                <InscreverEvento idEvento={this.state.idEvento} />


                <section data-bs-version="5.1" class="header9 cid-sZZW2BReRK" id="header9-x">
                    <div class="text-center container">
                        <div class="row justify-content-center">
                            <div class="col-md-12 col-lg-9">
                                <div class="image-wrap mb-4">
                                    <img src={evento.imagem} alt={evento.nome} style={{ opacity: evento.encerrado ? 0.5 : 1 }} />
                                </div>
                                <h1 class="mbr-section-title mbr-fonts-style mb-3 display-2"><strong>{evento.nome}{evento.encerrado ? " [Encerrado]" : null}</strong></h1>
                                <p class="mbr-text mbr-fonts-style display-7">
                                    {evento.apresentacao}
                                </p>
                                <div class="mbr-section-btn mt-3">
                                    {
                                        !evento.encerrado ?
                                            <>
                                                {
                                                    this.state.usuario ?

                                                        <input type="button" className="btn btn-primary display-4" value="Inscreva-se" data-bs-toggle="modal" data-bs-target="#inscreverModal" ref={input => this.btnInscrever = input} />
                                                        :
                                                        <Link to={"/cadastrar/" + this.state.idEvento} className="btn btn-primary display-4">Inscreva-se</Link>
                                                }
                                            </>
                                            :
                                            null

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section data-bs-version="5.1" class="header9  cid-sZZW2BReRK" id="header9-x">
                    <div class="text-center container ">
                        <div class="row justify-content-center">
                            <div class="col-md-12 col-lg-9">
                                <h3 class="mbr-section-title mbr-fonts-style mb-3 text-muted"><strong>Mais informações</strong></h3>
                                <p class="mbr-text mbr-fonts-style display-7">
                                    {evento.descricao}
                                </p>
                            </div>
                        </div>
                    </div>
                </section>



                <ListarEquipe idEvento={evento.id} />


                <section data-bs-version="5.1" class="timeline1 cid-sZZZ4S9CBI" id="timeline1-14">


                    <div class="timelines-container container" mbri-timelines="">
                        <div class="mbr-section-head">
                            <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                                <strong>Duração</strong></h3>

                        </div>
                        <div class="row timeline-element mb-2 mt-4">
                            <div class="timeline-date col-12 col-md-6">
                                <div class="timeline-date-wrapper">
                                    <p class="mbr-timeline-date mbr-fonts-style display-5">
                                        <strong>{moment(evento.dataInicio).format('LLL')}</strong>
                                    </p>
                                </div>
                            </div>
                            <span class="iconBackground"></span>
                            <div class="col-12 col-md-6">
                                <div class="timeline-text-wrapper">
                                    <h4 class="mbr-timeline-title mbr-fonts-style mb-4 display-5"><strong>Início</strong></h4>

                                    <p class="mbr-text mbr-fonts-style display-7">
                                        Data de início do evento, inscreva-se agora para não perder a data e também a vaga!
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="row timeline-element mb-2">
                            <div class="col-12 col-md-6">
                                <div class="timeline-text-wrapper">
                                    <h4 class="mbr-timeline-title mbr-fonts-style mb-4 display-5"><strong>Fim</strong></h4>

                                    <p class="mbr-text mbr-fonts-style display-7">
                                        Data do fim do evento, você não acredita como passa rápido, inscreva-se agora e garanta o seu certificado.
                                    </p>
                                </div>
                            </div>
                            <span class="iconBackground"></span>
                            <div class="timeline-date col-12 col-md-6">
                                <div class="timeline-date-wrapper">
                                    <p class="mbr-timeline-date mbr-fonts-style display-5">
                                        <strong>{moment(evento.dataFim).format('LLL')}</strong>
                                    </p>
                                </div>
                            </div>
                        </div>










                    </div>
                </section>

                <section data-bs-version="5.1" class="map1 cid-sZZX5awQgR" id="map1-z">


                    <div class="container">
                        <div class="mbr-section-head mb-4">
                            <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                                <strong>Local</strong></h3>
                            <h4 class="mbr-section-subtitle mbr-fonts-style align-center mb-0 mt-2 display-5">
                                Endereço do curso ou treinamento</h4>
                        </div>
                        <div class="google-map"><iframe frameborder="0" style={{ border: 0 }} src={googleUrl} allowfullscreen=""></iframe></div>
                    </div>
                </section>
                {
                    !evento.encerrado ?
                        <section data-bs-version="5.1" class="info4 cid-sZZYlMnF5i" id="info4-12">


                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="col-12">
                                        <h3 class="mbr-section-title mbr-fonts-style display-1">
                                            <strong>Participe</strong></h3>
                                        <p class="mbr-text mbr-fonts-style mb-4 display-1">
                                            Não perca tempo as vagas são limitadas</p>
                                        <div class="link-wrap">
                                            <p class="link mbr-fonts-style mb-4 display-1">
                                                {this.state.usuario ? <a className="text-primary" data-bs-toggle="modal" data-bs-target="#inscreverModal" >Inscreva-se &gt;&gt;</a> :
                                                    <Link to={"/cadastrar/" + this.state.idEvento} className="text-primary">Inscreva-se &gt;&gt;</Link>}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        : null
                }

            </div >
        );
    }
}
