
import { Button } from 'bootstrap';
import InputMask from "react-input-mask";
import React, { Component } from 'react';
import { toast } from 'react-toastify';
import PostUsuario from '../Api/PostUsuario';
import moment from 'moment'
import { Redirect } from 'react-router';
import cookie from 'react-cookies';
import { Link } from 'react-router-dom';
import { Obrigatorio } from '../Tools/Obrigatorio';
import GetEventos from '../Api/GetEventos';

export class CadastrarAdmin extends Component {
    static displayName = CadastrarAdmin.name;
    constructor(props) {
        super(props);
        this.state = {
            estado: "RO",
            tipoPessoa: "F",
            cidade: "",
            eventos: [],
            idCurso: null
        }
    }

    carregarCursos = () => {
        GetEventos(false, (e) => this.setState({ eventos: e, loading: false }), (e) => console.log(e));
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.carregarCursos();

    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    isValid() {
        if (this.state.nome) {

            return true;


        } else {
            toast.error("Informe um nome.");
            return false;
        }

    }

    concluir() {
        if (this.isValid()) {
            PostUsuario({
                nome: this.state.nome,
                tipoPessoa: this.state.tipoPessoa,
                cpf: this.state.cpf ? this.state.cpf.replace(/[^\d]/g, '') : null,
                cnpj: this.state.cnpj ? this.state.cnpj.replace(/[^\d]/g, '') : null,
                email: this.state.email,
                cargo: this.state.cargo,
                instituicao: this.state.instituicao,
                telefone: this.state.telefone ? this.state.telefone.replace(/[^\d]/g, '') : null,
                senha: this.state.senha,
                sexo: this.state.sexo,
                nascimento: this.state.nascimento,
                estado: this.state.estado,
                cidade: this.state.cidade,
                endereco: this.state.endereco,
                eventoId: this.state.idCurso
            }).then(r => {
                if (r.ok) {
                    r.json().then(data => {
                        toast.success("Cadastro realizado com sucesso");
                        this.setState({ redirect: true });
                    })

                } else {
                    r.text().then(t => toast.error(t))
                }
            })
        }
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={"/admin/usuarios"} />
        }
        return (

            <div>
                <section data-bs-version="5.1" class="header19 cid-t0bvGWXrL2" style={{ paddingTop: 1 + "rem" }} id="header19-0">
                    <div class="container">
                        <div class="row justify-content-center mt-4">
                            <div class="offset-lg-3 mbr-form" data-form-type="formoid">



                                <div class="row">
                                    <div class="col-4 form-group" data-for="name">

                                        <h6 className="display-6">Nome completo<Obrigatorio /></h6>

                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input type="text" name="nome" placeholder="Informe o seu nome completo ou da instituição" class="form-control" value={this.state.nome} onChange={this.onChange} />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="tipoPessoa" id="inlineRadio1" value="F" checked={this.state.tipoPessoa == "F"} onChange={this.onChange} />
                                            <label class="form-check-label" for="inlineRadio1">Pessoa Física</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="radio" name="tipoPessoa" id="inlineRadio2" value="J" checked={this.state.tipoPessoa == "J"} onChange={this.onChange} />
                                            <label class="form-check-label" for="inlineRadio2">Pessoa Juridica</label>
                                        </div>
                                    </div>
                                </div>

                                {
                                    this.state.tipoPessoa == "F" ?
                                        <>
                                            <div class="row">
                                                <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                                    <h7 className="display-7">CPF</h7>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <InputMask name="cpf" mask="999.999.999-99" className="form-control" maskChar={null} value={this.state.cpf} onChange={this.onChange} placeholder="Informe o seu número de CPF" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                                    <h7 className="display-7">Cargo/Função</h7>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <input name="cargo" type="text" className="form-control" value={this.state.cargo} onChange={this.onChange} placeholder="Informe o seu cargo ou função" />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                                    <h7 className="display-7">Orgão</h7>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <input name="instituicao" type="text" className="form-control" value={this.state.instituicao} onChange={this.onChange} placeholder="Informe o nome da sua instituição" />
                                                </div>
                                            </div>
                                        </>
                                        :

                                        <>
                                            <div class="row">
                                                <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                                    <h7 className="display-7">CNPJ</h7>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <InputMask name="cnpj" mask="99.999.999/9999-99" className="form-control" maskChar={null} value={this.state.cnpj} onChange={this.onChange} placeholder="Informe o número de CNPJ" />
                                                </div>
                                            </div>
                                        </>
                                }
                                <div class="row">
                                    <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                        <h7 className="display-7">E-mail</h7>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input type="email" name="email" placeholder="exemplo@email.com" class="form-control" value={this.state.email} onChange={this.onChange} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                        <h7 className="display-7">Telefone</h7>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-8 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <InputMask mask={"(99) 99999-9999"} name="telefone" placeholder="DD + Telefone" class="form-control" value={this.state.telefone} onChange={this.onChange} />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Senha</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input type="text" name="senha" placeholder="Crie uma senha de 6 a 18 caracteres" class="form-control" value={this.state.senha} onChange={this.onChange} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Confirmar senha</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input type="text" name="confirmarSenha" placeholder="Repita a senha anterior" class="form-control" value={this.state.confirmarSenha} onChange={this.onChange} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-8 col-12 " data-for="name">
                                        <hr />
                                    </div>
                                </div>
                                {
                                    this.state.tipoPessoa === "F" ?
                                        <>
                                            <div class="row">
                                                <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <h6 className="display-7">Sexo</h6>
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="_lblSexoM" value="M" checked={this.state.sexo == "M"} onChange={() => this.setState({ sexo: "M" })} />
                                                        <label class="form-check-label" for="_lblSexoM">Masculino</label>
                                                    </div>
                                                    <div class="form-check form-check-inline">
                                                        <input class="form-check-input" type="radio" name="inlineRadioOptions" id="_lblSexoF" value="F" checked={this.state.sexo == "F"} onChange={() => this.setState({ sexo: "F" })} />
                                                        <label class="form-check-label" for="_lblSexoF">Feminino</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <h6 className="display-7">Data de nascimento</h6>
                                                </div>
                                                <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                                    <input type="date" name="name" placeholder="Data e hora do início do evento" class="form-control" value={this.state.nascimento ? this.state.nascimento : ""} onChange={(e) => this.setState({ nascimento: moment(e.target.value).format("YYYY-MM-DD") })} />
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                }


                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Estado</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <select className="form-control" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado}>
                                            <option>AC</option>
                                            <option>AL</option>
                                            <option>AP</option>
                                            <option>AM</option>
                                            <option>BA</option>
                                            <option>CE</option>
                                            <option>DF</option>
                                            <option>ES</option>
                                            <option>GO</option>
                                            <option>MA</option>
                                            <option>MT</option>
                                            <option>MS</option>
                                            <option>MG</option>
                                            <option>PA</option>
                                            <option>PB</option>
                                            <option>PR</option>
                                            <option>PE</option>
                                            <option>PI</option>
                                            <option>RJ</option>
                                            <option>RN</option>
                                            <option>RS</option>
                                            <option>RO</option>
                                            <option>RR</option>
                                            <option>SC</option>
                                            <option>SP</option>
                                            <option>SE</option>
                                            <option>TO</option>

                                        </select>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Cidade</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input type="text" name="cidade" placeholder="Informe sua cidade" class="form-control" value={this.state.cidade} onChange={this.onChange} />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <h6 className="display-7">Endereço</h6>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12 form-group mb-3" data-for="name">
                                        <input type="text" name="endereco" placeholder="Informe seu bairro, logradouro e número" class="form-control" value={this.state.endereco} onChange={this.onChange} />
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-lg col-md-12 col-sm-12 " data-for="name">
                                        <h7 className="display-7">Curso</h7>
                                    </div>
                                </div>
                                <div className="col-8 form-group" data-for="name">
                                    <h6 className="display-6">
                                        <select className="form-control" onChange={(e) => this.setState({ idCurso: e ? parseInt(e.target.value) : null }, this.carregarInscricoes)}>
                                            <option value={0}>Selecione</option>
                                            {
                                                this.state.eventos.map((e) => <option key={e.id} value={e.id}>{e.nome}{e.encerrado ? " - [Encerrado]" : null}</option>)
                                            }
                                        </select>
                                    </h6>
                                </div>

                                <div class="row">
                                    <div class="col col-lg-8 text-end" data-for="name">
                                        <input type="button" className="btn btn-primary" value="Concluir" onClick={(e) => this.concluir()} />
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </section>
            </div >
        );
    }
}
