import cookie from 'react-cookies';
const PostEvento = (evento,onSuccess,onFailure) => {
    const token = cookie.load('access_token');

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(evento)
    };
    fetch('api/evento', requestOptions).then(r => {
        if (r.ok) {
            r.text().then(t => onSuccess(t));
        } else {
            r.text().then(t => onFailure(t));
        }
    })

};

export default PostEvento;