import React, { Component } from 'react';

import { NavMenu } from './NavMenu';
import { Rodape } from './Rodape';

export class Layout extends Component {
  static displayName = Layout.name;

  render () {
    return (
      <div>
        <NavMenu />
            <div>
               
                {this.props.children}
                <a href="https://api.whatsapp.com/send?phone=556993927078" className="float no-print" target="_blank">
                    <i class="fa fa-whatsapp my-float"></i>
                </a>
                <Rodape/>
        </div>
      </div>
    );
  }
}
