import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import cookie from 'react-cookies';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true,
            usuario: cookie.load('usuario'),
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }
    render() {
        return (
            <section data-bs-version="5.1" className="menu cid-s48OLK6784 no-print" id="menu1-h">

                <nav className="navbar navbar-dropdown navbar-fixed-top navbar-expand-lg" style={{ width: 100 + "%" }}>
                    <div className="container">
                        <div className="navbar-brand">
                            <span className="navbar-logo">
                                <Link to={"/"}>
                                    <img src={require('../images/logo.png')} alt="Mobirise Website Builder" style={{ height: 3.8 + "rem" }} />
                                </Link>
                            </span>

                        </div>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-target="#navbarSupportedContent" data-bs-target="#navbarSupportedContent" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                            <div className="hamburger">
                                <span></span>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
                                <NavItem className="nav-item">
                                    <NavLink tag={Link} className="nav-link link text-black display-4" to="/">Início</NavLink>
                                </NavItem>
                                <NavItem className="nav-item">
                                    <a href="#sobre-nos" className="nav-link link text-black display-4">Sobre nós</a>
                                </NavItem>
                                <NavItem className="nav-item">
                                    <NavLink tag={Link} className="nav-link link text-black display-4" to="/eventos">Cursos &amp; Treinamentos</NavLink>
                                </NavItem>
                                <NavItem className="nav-item">
                                    <NavLink tag={Link} className="nav-link link text-black display-4" to="/painel">Área do usuário</NavLink>
                                </NavItem>
                                {
                                    this.state.usuario && this.state.usuario.admin ?
                                        <>
                                            <NavItem className="nav-item">
                                                <NavLink tag={Link} className="nav-link link text-black display-4" to="/admin/usuarios">Usuários</NavLink>
                                            </NavItem>
                                            <NavItem className="nav-item">
                                                <NavLink tag={Link} className="nav-link link text-black display-4" to="/admin/inscricoes">Inscrições</NavLink>
                                            </NavItem>
                                        </>
                                        : null
                                }
                            </ul>


                        </div>
                    </div>
                </nav>

            </section>
        );
    }
}
