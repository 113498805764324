import React, { Component } from 'react';


export class Obrigatorio extends Component {
    static displayName = Obrigatorio.name;
    

    render() {

        return (
            <span style={{color:"red"}}>
              *
            </span>
        );
    }
}
