
import { Button } from 'bootstrap';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import CardEvento from '../Tools/CardEvento';
import { toast } from 'react-toastify'
import GetEventos from '../Api/GetEventos';
import { CadastrarEvento } from '../Evento/CadastrarEvento';
import cookie from 'react-cookies';

export class Eventos extends Component {
    static displayName = Eventos.name;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            idEvento: 0,
            usuario: cookie.load("usuario")
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.carregarEventos();
    }

    carregarEventos() {
        GetEventos(this.props.meusCursos, (d) => this.setState({ eventos: d, loading: false }), (t) => toast.error(t));
    }
    editarEvento(idEvento) {
        this.CadastrarEvento.carregarEvento(idEvento);
    }

    render() {
        if (this.state.loading)
            return "Carregando..."
        return (
            <div>

                <section data-bs-version="5.1" class="form8 cid-t0bvGWXrL2" style={{ paddingBottom: 0 }} id="form8-2">


                    <div class="container">
                        <div class="mbr-section-head">
                            <h3 class="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                                {this.props.meusCursos?<strong>Meus Cursos</strong>:<strong>Cursos & Treinamentos</strong>}
                            </h3>
                        </div>
                        <div class="row justify-content-center mt-4">
                            <div class="col- mx-auto mbr-form" data-form-type="formoid">


                                {
                                    this.state.usuario && this.state.usuario.admin ?
                                        <div class="dragArea row">
                                            <div class="col-lg-3 col-md-12 col-sm-12 form-group mb-3">
                                                <input type="button" name="email" className="form-control btn-success" value="Cadastrar" data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={(e) => this.editarEvento(null)} />

                                            </div>
                                        </div>
                                        : null
                                }


                            </div>
                        </div>
                    </div>
                </section>

                <CadastrarEvento
                    ref={(ev) => this.CadastrarEvento = ev}
                    carregarEventos={() => this.carregarEventos()}
                />


                <section data-bs-version="5.1" class="team2 cid-t0bvGWXrL2" style={{ paddingTop: 2 + "rem" }} xmlns="http://www.w3.org/1999/html" id="team2-1">


                    <div class="container">
                        {
                            this.state.eventos.length > 0 ?
                                this.state.eventos.map((e, i) =>
                                    <CardEvento evento={e} key={i}
                                        editarEvento={(idEvento) => this.editarEvento(idEvento)}
                                        meusCursos={ this.props.meusCursos}

                                    />
                                )
                                : "Nenhum evento cadastrado"
                        }
                    </div>
                </section>
            </div >
        );
    }
}
