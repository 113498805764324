
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import GetEventoByUrl from '../Api/GetEventoByUrl';



export class Redirecionar extends Component {
    static displayName = Redirecionar.name;
    constructor(props) {
        super(props);
        this.state = {
            url: this.props.match.params.url,
            loading: true,
            redirecionar: false,
        };
    }

    componentDidMount() {
        this.carregarEvento();
    }
    carregarEvento() {
        GetEventoByUrl(this.state.url, (d) => this.setState({ loading:false, redirecionar: d.id }))
    }

    render() {
        if (this.state.loading)
            return "Carregando..."
        else if (this.state.redirecionar)
            return <Redirect to={"/eventos/" + this.state.redirecionar} />
    }
}


