import cookie from 'react-cookies';
const DeleteEvento = (idEvento,onSuccess,onFailure) => {
    const token = cookie.load('access_token');

    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
      
    };
    return fetch('api/evento/delete/' + idEvento, requestOptions).then(r => {
        if (r.ok) {
            r.text().then(t => onSuccess(t));
        } else {
            r.text().then(t => onFailure(t));
        }
    })

};

export default DeleteEvento;