import cookie from 'react-cookies';
const PostMembro = (membro) => {
    const token = cookie.load('access_token');

    const requestOptions = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(membro)
    };
    return fetch('api/membro/', requestOptions)

};

export default PostMembro;