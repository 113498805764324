
import React, { Component } from 'react';
import { Link } from 'react-router-dom';


export class SejaMembro extends Component {
    static displayName = SejaMembro.name;
    constructor(props) {
        super(props);
        this.state = {
            nome: "",
            email: "",
        }
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    render() {
        return (
            <section data-bs-version="5.1" class="form3 cid-sZYQ9vTUvQ" id="form3-p">
                <div class="container-fluid">
                    <div class="row justify-content-center">
                        <div class="col-lg-7 col-12">
                            <div class="image-wrapper">
                                <img class="w-100" src={require("../../images/backgroundcontato.jpg")} alt="Mobirise Website Builder" />
                            </div>
                        </div>
                        <div class="col-lg-3 offset-lg-1 mbr-form" >
                            <div method="POST" class="mbr-form form-with-styler" >

                                <div class="row">
                                </div>
                                <div class="dragArea row">
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <h1 class="mbr-section-title mb-4 display-2">
                                            <strong>Seja membro</strong></h1>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12">
                                        <p class="mbr-text mbr-fonts-style mb-4 display-7">Não perca tempo, cadastre-se agora e garanta o seu aperfeiçoamento se increvendo nos cursos e treinamentos disponíveis.</p>
                                    </div>
                                    <div data-for="name" class="col-lg-12 col-md col-sm-12 form-group mb-3">
                                        <input type="text" name="nome" placeholder="Nome da pessoa física ou jurídica" class="form-control" value={this.state.nome} onChange={this.onChange} />
                                    </div>
                                    <div class="col-lg-12 col-md col-sm-12 form-group mb-3" data-for="email">
                                        <input type="email" name="email" placeholder="E-Mail" class="form-control" value={this.state.email} onChange={this.onChange} />
                                    </div>
                                    <div class="col-md-auto col-12 mbr-section-btn"><Link to={{
                                        pathname: "/cadastrar",
                                        nome: this.state.nome,
                                        email: this.state.email,
                                    }} class="btn btn-black display-4">Cadastre-se</Link></div>
                                </div>
                            </div>
                        </div>
                        <div class="offset-lg-1"></div>
                    </div>
                </div>
            </section>
        );
    }
}
